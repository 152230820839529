<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'ClubIndex'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.inviting-trainer')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label position="floating">{{ __('interface.first-name') }}</ion-label>
        <ion-input type="text" v-model="form.firstName" required autocapitalize="words"/>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ __('interface.last-name') }}</ion-label>
        <ion-input type="text" v-model="form.lastName" required autocapitalize="words"/>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ __('interface.email') }}</ion-label>
        <ion-input type="text" v-model="form.email" required/>
      </ion-item>
      <ion-button expand="full" class="ion-margin-vertical" :disabled="loadingState" @click="actionInvite">
        <span v-if="loadingState">{{ __('interface.inviting') }}</span>
        <span v-else>{{ __('interface.invite-your-trainer') }}</span>
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  toastController,
  alertController
} from "@ionic/vue";
import AuthService from "../AuthService";
import {passwordRequirements} from "../../utils/passwordRequirements";
import {API} from "../../utils/apiWrapper";

export default {
  name: "ChangePassword",
  mixins: [passwordRequirements, AuthService],
  components: {IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton, IonPage},
  props: {
    close: {type: Function}
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
      },
      loadingState: false,
    }
  },
  methods: {
    async actionInvite() {
      this.loadingState = true
      await API.post('TheSpottApi', '/club/invite', {
        body: this.form
      }).then(async res => {
        this.$tracking.event({
            eventName: 'club-invitation-sent',
            category: 'invitations',
            event: this.form
        });
        this.form = {
          firstName: '',
          lastName: '',
          email: ''
        }
        for (const message of res.messages) {
          const alert = await alertController.create({
            header: 'Success!',
            message: this.__(message.content),
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  this.$router.push({name: 'ClubIndex'})
                },
              },
            ],
          });
          await alert.present();
        }
      }).catch(async err => {
        for (const message of err.response.data.messages) {
          const toast = await toastController.create({
            message: this.__(message.content),
            duration: 2000
          })
          await toast.present();
        }
      }).finally(() => {
        this.loadingState = false
      })
    }
  },
}
</script>